import React from 'react';

//const st = process.env.PUBLIC_URL;

function ErrorPage() {
    return (
        <>
            <h3>Oops! Something goofed</h3>
        </>
    );
}

export default ErrorPage;